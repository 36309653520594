import React from "react";
import { ImageConstants } from "../../constants/Imagconstants";
import Layout from "../../components/Layout";
import { routes } from "../../constants/routes";
import { Link } from "gatsby";

const jobDeatils = ({ location }) => {
  const jobDetail = location?.state;
  return (
    <Layout title={"Razrcorp | Job Deatils"}>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="developer-title">
                  <p className="bradcum">
                    <Link className="bradcum" to={routes.careers}>
                      {"Career > "}
                    </Link>
                    <span className="pink-color">{jobDetail?.name}</span>
                  </p>
                  <h1>{`0${jobDetail?.vacancies}`}</h1>
                  <h2>{jobDetail?.name}</h2>
                  <p className="location">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.661"
                      height="30.788"
                      viewBox="0 0 21.661 30.788"
                    >
                      <g
                        id="Group_18145"
                        data-name="Group 18145"
                        transform="translate(-17036.969 -12240)"
                      >
                        <path
                          id="Path_98664"
                          data-name="Path 98664"
                          d="M219.33,33.816a10.844,10.844,0,0,0-10.83,10.83,11.291,11.291,0,0,0,1.35,4.389c.793,1.708,1.86,3.627,2.984,5.55,2.248,3.846,4.76,7.724,5.739,9.561a.865.865,0,0,0,1.526,0c.977-1.837,3.478-5.715,5.726-9.561,1.124-1.923,2.191-3.842,2.984-5.55a11.291,11.291,0,0,0,1.35-4.389,10.847,10.847,0,0,0-10.83-10.83Zm0,1.728a9.093,9.093,0,0,1,9.1,9.1,11.447,11.447,0,0,1-1.188,3.66c-.754,1.622-1.793,3.516-2.9,5.415-1.794,3.069-3.757,6.133-5.01,8.264-1.254-2.131-3.216-5.2-5.01-8.264-1.11-1.9-2.15-3.793-2.9-5.415a11.438,11.438,0,0,1-1.188-3.66,9.09,9.09,0,0,1,9.1-9.1Z"
                          transform="translate(16828.469 12206.184)"
                          fill="#2e3652"
                          fillRule="evenodd"
                        ></path>
                        <path
                          id="Path_98667"
                          data-name="Path 98667"
                          d="M286.482,102.49a5.212,5.212,0,1,1-5.216,5.212A5.2,5.2,0,0,1,286.482,102.49Z"
                          transform="translate(16761.318 12142.81)"
                          fill="#2e3652"
                          fillRule="evenodd"
                        ></path>
                      </g>
                    </svg>
                    <span>{jobDetail?.location}</span>
                  </p>
                  <p>
                    <a
                      href="https://forms.gle/zKJGAnQDLVobbWWk7"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Apply Now
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="job-summery-main">
                  {jobDetail?.summary?.JobSummary?.map((summary) => {
                    return (
                      <>
                        <div className="job-summery-title">
                          <figure>
                            <img
                              loading="lazy"
                              src={ImageConstants.jobSummary} //summary.icon
                              alt="summery-icon"
                            />
                          </figure>
                          <h5>{summary?.title}</h5>
                        </div>
                        {summary?.description.map((content) => {
                          return (
                            <div className="job-summery-body">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26.903"
                                height="17.039"
                                viewBox="0 0 26.903 17.039"
                              >
                                <path
                                  id="Path_99151"
                                  data-name="Path 99151"
                                  d="M252.124,138.909a1.043,1.043,0,0,0,.617-.253l7.623-7.174a.9.9,0,0,0,.035-1.268.924.924,0,0,0-1.268-.035l-6.109,5.759V112.9a.9.9,0,0,0-1.793,0v23.036l-6.109-5.759a.939.939,0,0,0-1.268.035.9.9,0,0,0,.035,1.268l7.623,7.174a.8.8,0,0,0,.616.253Z"
                                  transform="translate(-112.005 260.644) rotate(-90)"
                                  fill="#8099f6"
                                />
                              </svg>
                              <p>{content.Summary.data.Summary}</p>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default jobDeatils;
